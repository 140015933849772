.App {
  text-align: center;
  background-color: #ffffff;
}

h1 {
  color: #0088cc;
}

/* Настроим стиль для тултипов */
.ant-tooltip-inner {
  font-size: 16px; /* Установите нужный размер шрифта */
}

.App .header {
  display: flex;
  align-items: center; /* Вертикальное выравнивание элементов по центру */
  justify-content: space-between; /* Это изменяет выравнивание, чтобы логотип был слева, а заголовок по центру */
  padding: 20px 50px; /* Отступы сверху и снизу для контейнера */
}

.App .logo {
  height: 50px; /* Ограничиваем высоту логотипа */
  width: auto;  /* Сохраняем пропорции */
  margin-right: 20px; /* Отступ справа, чтобы логотип не прижимался к тексту */
}

.App .title {
  font-size: 24px;
  text-align: center; /* Центрирование текста */
  margin: 0;
  position: absolute; /* Это помогает выровнять заголовок по центру */
  left: 50%;
  transform: translateX(-50%); /* Центрируем текст по горизонтали */
}

/* Медиазапрос для экранов с шириной 885px и меньше */
@media (max-width: 885px) {
  .App .header {
    padding: 20px 4px; /* Убираем отступы справа и слева */
  }
  .App .logo {
    height: 40px; /* Ограничиваем высоту логотипа */
  }
}

@media (max-width: 620px) {
  .App .logo {
    height: 35px; /* Ограничиваем высоту логотипа */
  }
}

@media (max-width: 420px) {
  .App .logo {
    height: 30px; /* Ограничиваем высоту логотипа */
  }
}
